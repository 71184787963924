import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import {
    approvalDetails_action, approvalReject_action,
} from '../../../actions/time_sheet_expense_action/approval_action'
import { fill_action } from '../../../actions/master_action/checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import {
    clientsId, financialYearId, engagementPartnerId,
    engagementTimeForApproveSheetActionId, transcationForActionId, employeeActionId,
    taskActionId, transcationTypeActionId,
    finYearOnbasicClientActionId, expenseTypeActionId
} from '../../../utils/constants'
import {
    TimesheetDetails_action,
} from '../../../actions/time_sheet_expense_action/time_sheet_action'
import {
    expenseDetails_action,
} from '../../../actions/time_sheet_expense_action/expense_action'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import TimeField from 'react-simple-timefield';
import { data } from 'jquery';
import Resizer from "react-image-file-resizer";

/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));


class ApprovalU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: '',
            isOpen: false,
            is_add: false,
            is_delete: false,
            is_update: false,
            isLoading: false,
            transactionForId: '',
            transactionForList: [],
            finYearId: '',
            financialYearList: [],
            clientNameList: [],
            clientNameId: '',
            engagementId: '',
            engagementList: [],
            expenseTypeId: '',
            expenseTypeList: [],
            employeeId: '',
            employeeList: [],
            taskId: '',
            taskList: [],
            description: '',
            date: '',
            amount: '',
            remark: '',
            rejectionReason: '',
            isPaidByCreditCard: false,
            isNeedSupporting: false,
            timeSheetID: '',
            expenseTypeID: '',
            expenseHeadDescription: '',
            approvedBy: '',
            approvedBy: '',
            expenseId: '',
            documentFilesList: [],
            is_Details: true,
            _isApprove: false,
            _isReject: false,
            status: '',
            taskList: [],
            transactionTypeList: [],
            TimeSheetStatus: '',

            expenseclientNameId: '',
            expensetransactionForId: '',
            expensefinYearId: '',
            expenseengagementId: '',
            expenseemployeeId: '',
            expensedescription: '',
            expenseremark: '',
            expensedate: '',
            expenserejectionReason: '',
            expensetimeSheetExpenseID: '',
            expenseexpenseId: '',
            expenseamount: '',
            expenseexpenseTypeId: '',
            expenseisNeedSupporting: '',
            expenseisPaidByCreditCard: '',
            expenseExpenseStatus: '',
            pickedFileList: [],
            documentFilesList: [],

            timesheetShow: false,
            expenseShow: false,
            Previous_reject: '',
        }
        this.getFillList = this.getFillList.bind(this)
        // this.getDetailsTimesheetApproval = this.getDetailsTimesheetApproval.bind(this)
        this.handleTransactionForId = this.handleTransactionForId.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
    }

    componentDidMount() {


        this.getFillList(transcationForActionId, '')
        this.getFillList(employeeActionId, '')
        this.getFillList(clientsId, '')
        this.getFillList(taskActionId, '')
        this.getFillList(transcationTypeActionId, '')
        this.getFillList(expenseTypeActionId, '')

        this.getFillList(financialYearId, '')


        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            engagementAllocationId: this.props.location.state != null && this.props.location.state.engagementAllocationId != null ? this.props.location.state.engagementAllocationId : '',
            isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,
            timeSheetID: this.props.location.state != null && this.props.location.state.timeSheetID != null ? this.props.location.state.timeSheetID : '',
            expenseId: this.props.location.state != null && this.props.location.state.expenseID != null ? this.props.location.state.expenseID : '',
            Previous_reject: this.props.location.state != null && this.props.location.state.Previous_reject != null ? this.props.location.state.Previous_reject : '',
        })

        if (this.props.location.state != null && this.props.location.state.is_Details) {
            if (this.props.location.state.timeSheetID != 0 && this.props.location.state.expenseID != null) {
                this.getDetailsTimesheet()
                this.getDetailsExpense()
                this.setState({ timesheetShow: true, expenseShow: true })
            } else if (this.props.location.state.timeSheetID == 0 && this.props.location.state.expenseID != null) {
                this.getDetailsExpense()
                this.setState({ timesheetShow: false, expenseShow: true })
            } else {
                this.getDetailsTimesheet()
                this.setState({ timesheetShow: true, expenseShow: false })
            }
        }

        console.log("", this.props)
        // document.addEventListener('click', this.handleInputClick);

        // document.addEventListener('keydown', this.handleTabKeyPress);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    // get Quality Reviewer Name List dropdown list 
    getFillList(value, ref, ref2) {
        const { clientNameId, is_add, timeSheetID } = this.state
        // this.setState({ isLoading: true })

        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    // if (data.data.length == 0) {

                    if (value == clientsId) {
                        this.setState({ clientNameList: data.data, isLoading: false })
                        console.log("clientNameList", data.data)
                    }
                    if (value == transcationForActionId) {
                        this.setState({ transactionForList: data.data, isLoading: false })
                    }
                    if (value == employeeActionId) {
                        this.setState({ employeeList: data.data, isLoading: false })
                    }
                    if (value == expenseTypeActionId) {
                        this.setState({ expenseTypeList: data.data, isLoading: false })
                    }
                    if (value == engagementTimeForApproveSheetActionId) {
                        this.setState({ engagementList: data.data, isLoading: false })
                        console.log("engagementTimeForApproveSheetActionId", data.data)
                    }
                    if (value == financialYearId) {
                        this.setState({ financialYearList: data.data, isLoading: false })
                        console.log("financialYearId", data.data)

                    }
                    if (value == taskActionId) {
                        this.setState({ taskList: data.data })
                        console.log('employeeActionId')
                    }
                    if (value == transcationTypeActionId) {
                        this.setState({ transactionTypeList: data.data })
                        console.log('employeeActionId')
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    // get timesheet details
    getDetailsTimesheet() {
        this.setState({ isLoading: true })
        var id = this.props.location.state != ' ' && this.props.location.state.timeSheetID != ' ' ? this.props.location.state.timeSheetID : ''

        this.props
            .TimesheetDetails_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log("getDetailsTimesheet2--data+++", data)
                    this.getFillList(finYearOnbasicClientActionId, data.data[0]['ClientID'], data.data[0]['EngagementID'])
                    this.getFillList(engagementTimeForApproveSheetActionId, data.data[0]['ClientID'], data.data[0]['FinYearID'])
                    // employee id
                    data.data.map(item => {
                        if (item.empsFirstName == localStorage.getItem('employeeIdSS')) {
                            console.log("getDetailsTimesheet2", item.emmnID)
                            this.setState({ employeeId: item.emmnID })
                        }
                    })
                    setTimeout(() => {
                        this.setState({
                            clientNameId: data.data[0]['ClientID'],
                            transactionForId: data.data[0]['TransactionForID'],
                            finYearId: data.data[0]['FinYearID'],
                            engagementId: data.data[0]['EngagementID'],
                            TimeSheetStatus: data.data[0]['TimeSheetStatus'],
                            transactionTypeId: data.data[0]['TransactionTypeID'],
                            employeeId: data.data[0]['EmployeeID'],
                            taskId: data.data[0]['TaskTypeID'],
                            description: data.data[0]['Description'],
                            date: moment(data.data[0]['TimeSheetDate'], 'YYYY-MM-DD').toDate(),
                            fromTime: data.data[0]['StartTime'],
                            toTime: data.data[0]['Endtime'],
                            remark: data.data[0]['Remark'],
                            rejectionReason: data.data[0]['RejectReason'],
                            timeSheetExpenseID: data.data[0]['TimeSheetID'],
                            ExpenseAmmount: data.data[0]['ExpenseAmount'],
                            ExpenseId: data.data[0]['ExpenseID'],
                            isLoading: false
                        })
                    }, 300);
                    this.setState({
                        engagementId: data.data[0]['EngagementID'],
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })

            .catch((e) => {
                console.log(e);
            });
    }

    // get Expense details
    getDetailsExpense() {
        this.setState({ isLoading: true })
        const { documentFilesList } = this.state

        var id = this.props.location.state != ' ' && this.props.location.state.expenseID != ' ' ? this.props.location.state.expenseID : ''

        this.props
            .expenseDetails_action(id)
            .then((data) => {
                if (data.error != 1) {

                    data.data[0]['fileData'].length > 0 && data.data[0]['fileData'].map(data => {
                        if (data.name != null) {
                            documentFilesList.push({
                                'File': data.File,
                                'FileName': data.name,
                                'ExpDocumentID': data.ExpDocumentID,
                                'actionId': 0,
                            })
                        }
                    })

                    this.setState({
                        expenseclientNameId: data.data[0]['ClientID'],
                        expensetransactionForId: data.data[0]['TransactionForID'],
                        expensefinYearId: data.data[0]['FinYearID'],
                        expenseengagementId: data.data[0]['EngagementID'],
                        expenseemployeeId: data.data[0]['EmployeeID'],
                        expensedescription: data.data[0]['ExpenseDescription'],
                        expenseremark: data.data[0]['Remark'],
                        expensedate: data.data[0]['ExpenseDate'] != '' ? moment(data.data[0]['ExpenseDate'], 'YYYY-MM-DD').toDate() : '',
                        expenserejectionReason: data.data[0]['RejectReason'],
                        expensetimeSheetExpenseID: data.data[0]['TimeSheetID'],
                        expenseexpenseId: data.data[0]['ExpenseID'],
                        expenseamount: data.data[0]['Amount'],
                        expenseexpenseTypeId: data.data[0]['ExpenseHeadID'],
                        expenseisNeedSupporting: data.data[0]['NeedSupporting'],
                        expenseisPaidByCreditCard: data.data[0]['PaidBy'],
                        expenseExpenseStatus: data.data[0]['ExpenseStatus'],
                        expensepickedFileList: documentFilesList,
                        expensedocumentFilesList: documentFilesList,

                        isLoading: false,
                    })
                    this.getFillList(finYearOnbasicClientActionId, data.data[0]['ClientID'], data.data[0]['EngagementID'])
                    this.getFillList(engagementTimeForApproveSheetActionId, data.data[0]['ClientID'], data.data[0]['FinYearID'])
                    
                    if (data.data[0]['ExpenseStatus'] == "Approved") {
                        this.setState({ expenseDisable: true })
                    }

                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleTransactionForId(e) {
        this.setState({ transactionForId: e.target.value })
    }

    handleDownload(base64data, fileName) {

        const Data = base64data.split(';')
        const fileNameData = fileName.split('.')

        // Create a virtual link element
        const downloadLink = document.createElement('a');

        // Set the href attribute to the data URI
        downloadLink.href = base64data;

        // Set the download attribute with the desired file name
        if (Data[0] == 'data:application/pdf') {
            downloadLink.download = `${fileNameData[0]}.pdf`;
        } else {
            downloadLink.download = `${fileNameData[0]}.jpeg`;
        }

        // Append the link to the document body
        document.body.appendChild(downloadLink);

        // Trigger a click on the link to start the download
        downloadLink.click();

        // Remove the link from the document
        document.body.removeChild(downloadLink);
    };

    // clear field ,list
    handleCancel() {
        this.props.navigate('/approval')
    }

    onSelectFinYear(e, type) {
        const { clientNameId, } = this.state

        // time sheet 
        if (type = 'finYearId') {
            if (e.target.value = '') {
                this.setState({ finYearId: e.target.value, })
                this.getFillList(engagementTimeForApproveSheetActionId, clientNameId, e.target.value)

            } else {
                this.setState({ finYearId: '' })
            }
        } else {

            // expense
            if (e.target.value = '') {
                this.setState({ expensefinYearId: e.target.value, })
                this.getFillList(engagementTimeForApproveSheetActionId, clientNameId, e.target.value)

            } else {
                this.setState({ expensefinYearId: '' })
            }
        }
    }

    render() {
        const { isAccessToView, is_add, is_update, isOpen, isLoading,
            isActive,
            transactionForId, transactionForList, finYearId, engagementId, clientNameId, clientNameList,
            engagementList, expenseTypeId, expenseTypeList, employeeId, taskId,
            taskList, description, date, amount, remark, rejectionReason, employeeList,
            financialYearList, isNeedSupporting, isPaidByCreditCard, documentFilesList, is_Details, status,

            TimeSheetStatus, transactionTypeId, transactionTypeList, fromTime, toTime, isApproved, startDate, endDate, is_DateFlag, isClientDisable, timesheetDisable, isTimesheetSuccsess, singleDisableLastDateTimesheet, isDatePresent,

            ExpenseStatus, navigateTimesheetAdd, expenseDisable, is_Expenseupdate,

            expenseclientNameId,
            expensetransactionForId,
            expensefinYearId,
            expenseengagementId,
            expenseemployeeId,
            expensedescription,
            expenseremark,
            expensedate,
            expenserejectionReason,
            expensetimeSheetExpenseID,
            expenseexpenseId,
            expenseamount,
            expenseexpenseTypeId,
            expenseisNeedSupporting,
            expenseisPaidByCreditCard,
            expenseExpenseStatus,
            expensepickedFileList,
            expensedocumentFilesList,
            Previous_reject,
            timesheetShow, expenseShow

        } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    {/* partial:partials/_navbar.html */}
                    {/* partial */}
                    <div className="container-fluid page-body-wrapper">
                        {/* partial:partials/_settings-panel.html */}
                        <Drawer />
                        {/* start page */}
                        {/* {isAccessToView ? ( */}
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">

                                        <li>
                                            <a onClick={this.handleCancel}>Approval </a>
                                        </li>
                                        <li>
                                            <a>Details</a>
                                        </li>
                                        <li />
                                    </ul>
                                </div>
                                <div className="user-status">
                                    {
                                        is_Details ?
                                            <p>
                                                <strong className={TimeSheetStatus
                                                    === 'Entered' || 'Sent for Approval' || 'Approved' || 'Rejected' || 'Deleted' ? 'greena' : TimeSheetStatus == " " ? null : 'redi'}> {TimeSheetStatus

                                                    }</strong>
                                            </p>
                                            : <></>
                                    }
                                </div>
                                {/* Need Supporting */}
                                <div className='page-space-from-bottom'>
                                    <div
                                        className="tab-content"
                                        id="myTabContent">
                                        <div className="row" >
                                            <style
                                                type="text/css"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                                }}
                                            />

                                            {timesheetShow ?
                                                <>
                                                    <h5 className='mt-2' style={{ color: '#98bc17', marginLeft: '5px' }}>Timesheet</h5>
                                                    <div className="col-md-12 pg-body grid-margin stretch-card" >
                                                        <div className="card">
                                                            <div className="card-body">

                                                                <div className="d-flex flex-wrap row ">
                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Transaction For
                                                                            <span className="required1">*</span>
                                                                        </label>
                                                                        <select className={"js-example-basic-single bg-secondary w-100"}

                                                                            disabled={true}
                                                                            value={transactionForId}
                                                                            onChange={e => this.onSelectTransactionForId(e)}>
                                                                            <option value={''}>Select</option>
                                                                            {transactionForList.length > 0 ? transactionForList.map(item => (
                                                                                <option
                                                                                    key={item.TransactionForID}
                                                                                    value={item.TransactionForID}>
                                                                                    {item.TransactionFor}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-3 mt-1">
                                                                        <label>
                                                                            Employee
                                                                            {/* <span className="required1">*</span> */}
                                                                        </label>
                                                                        <select
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            disabled={true}
                                                                            value={employeeId}
                                                                            onChange={e => this.setState({ employeeId: e.target.value })}
                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {employeeList.length > 0 ? employeeList.map(item => (
                                                                                <option
                                                                                    key={item.emmnID}
                                                                                    value={item.emmnID}>
                                                                                    {item.empsFirstName}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Client
                                                                            <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                        </label>
                                                                        <select
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            style={{ width: "100%" }}
                                                                            value={clientNameId}
                                                                            disabled={true}
                                                                            onChange={e => this.onSelectClient(e)}
                                                                        >
                                                                            <option value=''>Select</option>
                                                                            {clientNameList.length > 0 ? clientNameList.map(item => (
                                                                                <option
                                                                                    key={item.ClientID}
                                                                                    value={item.ClientID}>
                                                                                    {item.ClientFName}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>

                                                                </div>

                                                                <div className="d-flex flex-wrap row mt-2">
                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Financial Year
                                                                            <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                        </label>
                                                                        <select
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            style={{ width: "100%" }}
                                                                            disabled={true}
                                                                            value={finYearId} onChange={e => this.onSelectFinYear(e, 'finYearId')}
                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {financialYearList.length > 0 ? financialYearList.map(item => (
                                                                                <option
                                                                                    key={item.FinYearID}
                                                                                    value={item.FinYearID}>
                                                                                    {item.FinancialYear}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Engagement <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                        </label>
                                                                        <select
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            style={{ width: "100%" }}
                                                                            disabled={true}
                                                                            value={engagementId}
                                                                            onChange={e => this.onSelectEngagementId(e)}
                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {engagementList.length > 0 ? engagementList.map(item => (
                                                                                <option
                                                                                    key={item.EngagementID}
                                                                                    value={item.EngagementID}>
                                                                                    {item.MainEngagement}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-3 mt-1">
                                                                        <label>
                                                                            Task
                                                                            {/* <span className="required1">*</span> */}
                                                                        </label>
                                                                        <select
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            disabled={true}
                                                                            style={{ width: "100%" }}
                                                                            value={taskId} onChange={e => this.setState({ taskId: e.target.value })}

                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {taskList.length > 0 ? taskList.map(item => (
                                                                                <option
                                                                                    key={item.tsknID}
                                                                                    value={item.tsknID}>
                                                                                    {item.tsksTaskType}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex flex-wrap row mt-2">
                                                                    <div className="form-group col-md-3 mt-1">
                                                                        <label>
                                                                            Transaction Type
                                                                            {/* <span className="required1">*</span> */}
                                                                        </label>
                                                                        <select
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            disabled={true}
                                                                            style={{ width: "100%" }}
                                                                            value={transactionTypeId} onChange={e => this.setState({ transactionTypeId: e.target.value })}

                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {transactionTypeList.length > 0 ? transactionTypeList.map(item => (
                                                                                <option
                                                                                    key={item.TransactionTypeID}
                                                                                    value={item.TransactionTypeID}>
                                                                                    {item.Name}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Date<span className="required1">*</span>
                                                                        </label>
                                                                        <div className="form-group d-flex">
                                                                            <DatePicker
                                                                                className={"js-example-basic-single form-control bg-secondary w-100"}
                                                                                dateFormat="dd-MM-yyyy"
                                                                                selected={this.state.date}
                                                                                disabled={true}
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                onChange={(date) => { this.onSelectDate(date) }}
                                                                                placeholderText="Date"
                                                                                customInput={<ExampleCustomInput />}
                                                                                dayClassName={() => "example-datepicker-day-class"}
                                                                                popperClassName="example-datepicker-class"
                                                                            />
                                                                            {/* {console.log("lastDateTimesheetFill", disableLastDateTimesheetFill)} */}
                                                                            {/* <FaCalendarAlt style={{
                                                                            flex: '0 0 19%',
                                                                            maxWidth: '19%',
                                                                            color: 'grey',
                                                                            marginTop: '7px',
                                                                            marginLeft: '-2rem',
                                                                            zIndex: '0'
                                                                        }} /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="d-flex flex-wrap row mt-2">
                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            From Time<span className="required1">*</span>
                                                                        </label>
                                                                        <div className="form-group d-flex">
                                                                            <input type='time'
                                                                                className={"js-example-basic-single bg-secondary w-100"}
                                                                                disabled={true}
                                                                                onChange={(e, time) => this.setState({ fromTime: e.target.value })}
                                                                                value={fromTime} />
                                                                            {/* <TimeField className="form-control" value={fromTime}  // this.setState({ fromTime: time })
                                                                        onChange={(event, time) => this.setState({ fromTime: time })} style={{ width: '25rem' }}></TimeField> */}

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            To  Time<span className="required1">*</span>
                                                                        </label>
                                                                        <div className="form-group d-flex">
                                                                            <input type='time'
                                                                                className={"js-example-basic-single bg-secondary w-100"}
                                                                                disabled={true}
                                                                                min={fromTime}
                                                                                //  disabled = {fromTime}
                                                                                onChange={(e, time) => this.setState({ toTime: e.target.value })} value={toTime} />
                                                                            {/* <TimeField className="form-control" value={toTime} min={minTime} /// this.setState({ toTime: time })
                                                                        onChange={(event, time) => this.setState({ toTime: time })} style={{ width: '25rem' }}></TimeField> */}


                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                {/* <div className="form-group col-md-6">
                                                                    <label>
                                                                        Description <span className="required1">*</span>
                                                                    </label>
                                                                    <textarea
                                                                        type="text"
                                                                        className={"js-example-basic-single bg-secondary w-100 h-auto rounded-1"}
                                                                        disabled={true}
                                                                        value={description}
                                                                        onChange={(e) => this.setState({ description: e.target.value })}
                                                                        required
                                                                    />
                                                                </div> */}






                                                                <div className="d-flex flex-wrap row mt-2">
                                                                    <div className="form-group col-md-6">
                                                                        <label>
                                                                            Description <span className="required1">*</span>
                                                                        </label>
                                                                        <textarea
                                                                            type="text"
                                                                            className={"js-example-basic-single bg-secondary w-100 h-auto rounded-1"}
                                                                            disabled={true}
                                                                            value={description}
                                                                            onChange={(e) => this.setState({ description: e.target.value })}
                                                                            required
                                                                            style={{ border: "1px solid #dee2e6" }}
                                                                        />
                                                                    </div>
                                                                </div>










                                                                <div className="d-flex flex-wrap row mt-2">
                                                                    <div className="form-group col-md-6">
                                                                        <label>Remark</label>
                                                                        <div className="form-group mb-3">
                                                                            <textarea
                                                                                className={"js-example-basic-single bg-secondary w-100 h-auto rounded-1"}
                                                                                disabled={true}
                                                                                name="remark"
                                                                                rows={2}
                                                                                cols={50}
                                                                                maxLength={250}
                                                                                style={{ border: "1px solid #dee2e6" }}
                                                                                // defaultValue={this.state.remark}
                                                                                value={this.state.remark}
                                                                                onChange={e => this.setState({ remark: e.target.value })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    TimeSheetStatus === 'Rejected' || Previous_reject === 'Yes' ?
                                                                        <div className="d-flex flex-wrap row mt-2">
                                                                            <div className="form-group col-md-6">
                                                                                <label>Rejection Reason  </label>
                                                                                <div className="form-group mb-3">
                                                                                    <textarea
                                                                                        maxLength={250}
                                                                                        className={"js-example-basic-single bg-secondary w-100"}
                                                                                        name="remark"
                                                                                        rows={4}
                                                                                        cols={50}
                                                                                        style={{ border: "1px solid #dee2e6" }}
                                                                                        disabled={true}
                                                                                        value={rejectionReason}
                                                                                        onChange={e => this.setState({ rejectionReason: e.target.value })}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : <></>}

                                            {expenseShow ?
                                                <>
                                                    <h5 className='mt-2' style={{ color: '#98bc17', marginLeft: '5px' }}>Expense</h5>
                                                    <div className="col-md-12 pg-body grid-margin stretch-card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                {/* <div className="row user-status">
                                                                <div className="status-top mr-2">
                                                                    {
                                                                        is_Details ? <p>
                                                                            <strong className={expenseExpenseStatus
                                                                                === 'Entered' || 'Sent for Approval' || 'Approved' || 'Rejected' || 'Deleted' ? 'greena' : 'redi'}> {expenseExpenseStatus
                                                                                }</strong>
                                                                        </p> : <></>
                                                                    }
                                                                </div>
                                                            </div> */}
                                                                <div className="d-flex flex-wrap row ">
                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Transaction For
                                                                            <span className="required1">*</span>
                                                                        </label>
                                                                        <select className={"js-example-basic-single bg-secondary w-100"}

                                                                            disabled={true}
                                                                            value={expensetransactionForId}
                                                                            onChange={e => this.onSelectTransactionForId(e)}>
                                                                            <option value={''}>Select</option>
                                                                            {transactionForList.length > 0 ? transactionForList.map(item => (
                                                                                <option
                                                                                    key={item.TransactionForID}
                                                                                    value={item.TransactionForID}>
                                                                                    {item.TransactionFor}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Employee
                                                                            {/* <span className="required1">*</span> */}
                                                                        </label>
                                                                        <select
                                                                            className={"js-example-basic-single bg-secondary"}
                                                                            style={{ width: "100%" }}
                                                                            disabled={true}
                                                                            value={expenseemployeeId}
                                                                            onChange={e => this.setState({ employeeId: e.target.value })}
                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {employeeList.length > 0 ? employeeList.map(item => (
                                                                                <option
                                                                                    key={item.emmnID}
                                                                                    value={item.emmnID}>
                                                                                    {item.empsFirstName}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Client
                                                                            <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                        </label>
                                                                        <select
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            style={{ width: "100%" }}
                                                                            value={expenseclientNameId}
                                                                            disabled={true}
                                                                            onChange={e => this.onSelectClient(e)}
                                                                        >
                                                                            <option value=''>Select</option>
                                                                            {clientNameList.length > 0 ? clientNameList.map(item => (
                                                                                <option
                                                                                    key={item.ClientID}
                                                                                    value={item.ClientID}>
                                                                                    {item.ClientFName}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>

                                                                </div>

                                                                <div className="d-flex flex-wrap row mt-2">
                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Financial Year
                                                                            <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                        </label>
                                                                        <select
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            style={{ width: "100%" }}
                                                                            disabled={true}
                                                                            value={expensefinYearId} onChange={e => this.onSelectFinYear(e, "expensefinYearId")}
                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {financialYearList.length > 0 ? financialYearList.map(item => (
                                                                                <option
                                                                                    key={item.FinYearID}
                                                                                    value={item.FinYearID}>
                                                                                    {item.FinancialYear}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Engagement <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                        </label>
                                                                        <select
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            style={{ width: "100%" }}
                                                                            disabled={true}
                                                                            value={expenseengagementId}
                                                                            onChange={e => this.onSelectEngagementId(e)}
                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {engagementList.length > 0 ? engagementList.map(item => (
                                                                                <option
                                                                                    key={item.EngagementID}
                                                                                    value={item.EngagementID}>
                                                                                    {item.MainEngagement}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Expense Type<span className="required1">*</span>
                                                                        </label>
                                                                        <select
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            disabled={true}
                                                                            style={{ width: "100%" }}
                                                                            value={expenseexpenseTypeId}
                                                                            onChange={e => this.setState({ expenseTypeId: e.target.value })}
                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {expenseTypeList.length > 0 ? expenseTypeList.map(item => (
                                                                                <option
                                                                                    key={item.ExpenseHeadID}
                                                                                    value={item.ExpenseHeadID}>
                                                                                    {item.ExpenseType}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>

                                                                    </div>
                                                                </div>

                                                                <div className="d-flex flex-wrap row mt-2">
                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Date<span className="required1">*</span>
                                                                        </label>
                                                                        <div className="form-group d-flex">
                                                                            <DatePicker
                                                                                className={"js-example-basic-single form-control bg-secondary w-100"}
                                                                                dateFormat="dd-MM-yyyy"
                                                                                selected={this.state.expensedate}
                                                                                disabled={true}
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                onChange={(date) => { this.onSelectDate(date) }}
                                                                                placeholderText="Date"
                                                                                customInput={<ExampleCustomInput />}
                                                                                dayClassName={() => "example-datepicker-day-class"}
                                                                                popperClassName="example-datepicker-class"
                                                                            />
                                                                            {/* <FaCalendarAlt style={{
                                                                            flex: '0 0 19%',
                                                                            maxWidth: '19%',
                                                                            color: 'grey',
                                                                            marginTop: '7px',
                                                                            marginLeft: '-3rem',
                                                                            zIndex: '0'
                                                                        }} /> */}
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Amount<span className="required1">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            disabled={true}
                                                                            pattern="[0-9]"
                                                                            value={expenseamount}
                                                                            onChange={(e) => this.setState({ amount: e.target.value.replace(/\D/, '') })}
                                                                            required
                                                                            maxLength={18}
                                                                        />

                                                                    </div>

                                                                    {/* <div className="form-group col-md-3">
                                                                        <label>
                                                                            Description
                                                                            <span className="required1">*</span>
                                                                        </label>
                                                                        <input
                                                                            className={"js-example-basic-single bg-secondary w-100"}
                                                                            disabled={true}
                                                                            type="text"
                                                                            maxLength={250}
                                                                            value={expensedescription}
                                                                            onChange={(e) => this.setState({ description: e.target.value })}
                                                                            required
                                                                        />
                                                                    </div> */}

<div className="d-flex flex-wrap row mt-2">
                                                                    <div className="form-group col-md-6 pr-0">
                                                                    <label>
                                                                        Description <span className="required1">*</span>
                                                                    </label>
                                                                    <textarea
                                                                        type="text"
                                                                        className={"js-example-basic-single bg-secondary w-100 h-auto rounded-1"}
                                                                        disabled={true}
                                                                        rows={2}
                                                                        cols={50}
                                                                        value={expensedescription}
                                                                        onChange={(e) => this.setState({ description: e.target.value })}
                                                                        required
                                                                        style={{ border: "1px solid #dee2e6" }}
                                                                    />
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-wrap row mt-2">
                                                                    <div className="form-group col-md-6 pr-0">
                                                                        <label>Remark</label>
                                                                        <div className="form-group mb-3">
                                                                            <textarea
                                                                                maxLength={250}
                                                                                className={"js-example-basic-single bg-secondary w-100 rounded-1"}
                                                                                disabled={true}
                                                                                name="remark"
                                                                                rows={2}
                                                                                cols={50}
                                                                                style={{ border: "1px solid #dee2e6" }}
                                                                                // defaultValue={this.state.remark}
                                                                                value={expenseremark}
                                                                                onChange={e => this.setState({ remark: e.target.value })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    
                                                                </div>

                                                                {expenseExpenseStatus === 'Rejected' || Previous_reject === 'Yes' ?
                                                                    <div className="d-flex flex-wrap row mt-2">
                                                                        <div className="form-group col-9">
                                                                            <label>Rejection Reason  </label>
                                                                            <div className="form-group mb-3">
                                                                                <textarea
                                                                                    className={"js-example-basic-single bg-secondary w-100"}
                                                                                    disabled={true}
                                                                                    name="remark"
                                                                                    maxLength={250}
                                                                                    rows={4}
                                                                                    cols={50}
                                                                                    style={{ border: "1px solid #dee2e6" }}
                                                                                    value={expenserejectionReason}
                                                                                    onChange={e => this.setState({ rejectionReason: e.target.value })}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : <></>}
                                                                <div className="d-flex flex-wrap row m-0 justify-content-evenly" style={{ marginLeft: '10px' }}>
                                                                    {/* <div className="form-check col d-flex">
                                                            <input type="checkbox"
                                                                checked={isPaidByCreditCard}
                                                                selected={isPaidByCreditCard}
                                                                value={isPaidByCreditCard}
                                                                onChange={e => this.setState({ isPaidByCreditCard: !isPaidByCreditCard })}
                                                            />
                                                            <label className="form-check-label" for="flexCheckDefault">
                                                                Paid By Credit Card
                                                            </label>
                                                        </div> */}
                                                                    {expenseisNeedSupporting ?
                                                                        <>
                                                                            {/* <div className="form-check col d-flex"> */}
                                                                            {/* <input type="checkbox"
                                                                        disabled={true}
                                                                        checked={expenseisNeedSupporting}
                                                                        selected={isNeedSupporting}
                                                                        value={expenseisNeedSupporting} /> */}
                                                                            <label className="form-check-label" for="flexCheckChecked">
                                                                                Supporting in document
                                                                            </label>
                                                                            {/* </div> */}

                                                                        </> : <></>}
                                                                </div>
                                                                {
                                                                    expenseisNeedSupporting ?
                                                                        <>
                                                                            {/* <div className="form-group col-md-3"> */}
                                                                            {/* <label>Document
                                                                        </label>
                                                                        <div className="input-group js-example-basic-single">
                                                                            <input type="file"
                                                                                className="form-control"
                                                                                disabled={true}
                                                                                id="inputGroupFile"
                                                                            />
                                                                        </div> */}
                                                                            {/* </div> */}
                                                                            <div className='col-md-5 bg-secondary'>
                                                                                {
                                                                                    documentFilesList.length > 0 && documentFilesList.map((data, index) => (
                                                                                        <ul class="list-group d-flex justify-content-around p-2">
                                                                                            {
                                                                                                < li className={"js-example-basic-single "} key={index}>{data.FileName}

                                                                                                    {is_Details ?
                                                                                                        <i
                                                                                                            type="button"
                                                                                                            className="fa fa-download  float-right"
                                                                                                            onClick={() => this.handleDownload(data.File, data.FileName)}
                                                                                                        >
                                                                                                        </i>
                                                                                                        : <></>}
                                                                                                </li>
                                                                                            }
                                                                                        </ul>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </> : <></>
                                                                }

                                                                <div style={{ height: 50 }} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : <></>}

                                            <div className="row">
                                                <div className="footer-btn">
                                                    <div className="col-md-3">
                                                        <div
                                                            className="btn-group"
                                                            role="group"
                                                            aria-label="Basic example"
                                                        >


                                                            {/* {
                                                                        is_update? */}
                                                            <button
                                                                type="button"
                                                                className="btn btn-cancel footer-btn-inner"
                                                                onClick={this.handleCancel}
                                                            >
                                                                Cancel
                                                            </button>
                                                            {/* :<></>
                                                                    } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    {/* end page */}
                                </div>
                            </div>
                            {/* page-body-wrapper ends */}
                        </div>
                        {/* ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)} */}
                    </div>
                </div >
                <div className='' style={{ height: '50%' }}></div>
                <ToastContainer />
            </>
        );
    }
}

export default withRouter(connect(null, {
    approvalDetails_action, approvalReject_action, TimesheetDetails_action,
    fill_action, expenseDetails_action
})(ApprovalU));